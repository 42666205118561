import {
    CircularProgress,
    LinearProgress,
    Stack,
    SxProps,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useFetcher } from '@remix-run/react';
import { mergeSx } from '~/utils/mui';

export const RecruitmentProgress = ({
    recruitedUsers,
    sx,
}: {
    fetcher: ReturnType<typeof useFetcher>;
    claimRewardRoute: string;
    recruitedUsers: number;
    sx?: SxProps<Theme>;
}): JSX.Element => {
    const recruitedUsersNeeded = 3;
    const theme = useTheme();

    const showLinearProgress = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Stack
            sx={mergeSx(
                {
                    flexDirection: showLinearProgress ? 'column-reverse' : 'row',
                },
                sx,
            )}
        >
            {showLinearProgress ? (
                <Stack sx={{ flexBasis: '100%' }}>
                    <LinearProgress
                        variant="determinate"
                        value={(recruitedUsers / recruitedUsersNeeded) * 100}
                        sx={{
                            '.MuiLinearProgress-bar1Determinate': { backgroundColor: theme.palette.cta.main },
                            backgroundColor: '#DBD8D4',
                            mt: 3,
                        }}
                    />
                </Stack>
            ) : (
                <Stack
                    sx={{
                        flexBasis: '15%',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        [theme.breakpoints.up('xl')]: {
                            flexBasis: '10%',
                        },
                        mr: 4,
                    }}
                >
                    <Stack sx={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress
                            variant="determinate"
                            value={
                                recruitedUsers > recruitedUsersNeeded
                                    ? 100
                                    : (recruitedUsers / recruitedUsersNeeded) * 100
                            }
                            sx={{
                                color:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.cta.main
                                        : theme.palette.common.white,
                                backgroundColor:
                                    theme.palette.mode === 'light' ? '#DBD8D4' : theme.palette.background.paper,
                                padding: '2px',
                                borderRadius: '50%',
                                '.MuiCircularProgress-circleDeterminate': {
                                    strokeLinecap: 'round',
                                },
                            }}
                            size={50}
                            thickness={5}
                        />
                        <Stack
                            sx={{
                                position: 'absolute',
                                backgroundColor:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[200]
                                        : theme.palette.background.dark,
                                height: '31px',
                                width: '31px',
                                borderRadius: '50%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {recruitedUsers >= recruitedUsersNeeded && <>🥳</>}
                        </Stack>
                    </Stack>
                </Stack>
            )}
            <Stack
                sx={{
                    flexBasis: '85%',
                    justifyContent: 'center',
                    [theme.breakpoints.up('xl')]: {
                        flexBasis: '90%',
                    },
                }}
            >
                <Typography variant="p2">
                    {recruitedUsers === 0 && (
                        <>
                            Ingen har brukt vervelenken ennå. Når du har vervet tre venner, får du gratis 3 måneders
                            fornyelse av abonnementet Allvit+.
                        </>
                    )}
                    {recruitedUsers > 0 && recruitedUsers < recruitedUsersNeeded && (
                        <>
                            <b>{recruitedUsers} personer</b> har brukt vervelenken. Du mangler bare{' '}
                            {recruitedUsersNeeded - recruitedUsers} til så får du gratis 3 måneders fornyelse av
                            abonnementet Allvit+.
                        </>
                    )}
                    {recruitedUsers === recruitedUsersNeeded && (
                        <>
                            Tre venner har brukt vervelenken din. Derfor får du gratis 3 måneders fornyelse av
                            abonnementet Allvit+.
                        </>
                    )}
                    {recruitedUsers > recruitedUsersNeeded && (
                        <>
                            Mer en {recruitedUsersNeeded} har brukt vervelenken din. Derfor får du gratis 3 måneders
                            fornyelse av abonnementet Allvit+.
                        </>
                    )}
                </Typography>
            </Stack>
        </Stack>
    );
};
